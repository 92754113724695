import React from 'react';

const Status = () => {
  const commitTitle = process.env.GATSBY_CI_COMMIT_TITLE;
  const commitMessage = process.env.GATSBY_CI_COMMIT_MESSAGE;
  const commitTimestamp = process.env.GATSBY_CI_COMMIT_TIMESTAMP;
  const commitSha = process.env.GATSBY_CI_COMMIT_SHA;

  return (
    <>
      <h2>Status Page</h2>
      <p>Latest commit:</p>
      <p>
        Commit Message:
        {' '}
        {commitMessage}
        <br />
        Commit Timestamp:
        {' '}
        {commitTimestamp}
        <br />
        Commit SHA:
        {' '}
        {commitSha}
      </p>
    </>
  );
}

export default Status;
