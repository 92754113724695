import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout/Layout';
import Sidebar from '../components/Sidebar/Sidebar';
import Status from '../components/Status/Status';

const shortcodes = { Link };

const MDXPageTemplate = (props) => {
  const { title, subtitle } = props.data.site.siteMetadata;
  const page = props.data.markdownRemark;
  // const { title: pageTitle, description: pageDescription } = page.frontmatter;
  // const description = pageDescription !== null ? pageDescription : subtitle;
  const { location } = props;
  const description = 'Test';
  const children = props?.data?.allMdx?.edges[0].node?.body;

  function H1() {
    return <p>HEADING 1</p>;
  }

  const components = {
    h1: H1,
    h2: H1,
  };

  return (
    <Layout location={location} title={description}>
      <div>
        <Sidebar {...props} lang="en" />
        <div className="content">
          <div className="my-10 mx-6">
            <Status />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MDXPageTemplate;

export const pageQuery = graphql`
  query MDXPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        url
        author {
          name
          nameFr
          twitter
          github
          rss
        }
      }
    }
    allMdx(filter: { frontmatter: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          frontmatter {
            slug
          }
          internal {
            contentFilePath
          }
          body
        }
      }
    }
  }
`;
